:global(:root) {
  // text
  --text: black;
  --secondary-text: #494949;
  
 --primary-color: #9e2a2b;
  --secondary-color: #f0f0f0;
  --third-color: #540b0e;
  --fourth-color: #38040e;
  --fifth-color: #FF7967;
  //
  --overlayBg: #ff796785;
  --borderRadius: .7rem;
  --padding: 2.5rem 2rem;
}

.button {
  width: 100%;
  border: none;
  margin-top: 3rem;
  padding: 1.5rem 2rem;
  display: block;
  border-radius: 3rem;
  background-color: var(--primary-color);
  color: white;
  font-size: 1.6rem;
  font-family: "Montserrat Medium";
  color: var(--secondary-color);
  text-align: center;

  &:hover {
    cursor: pointer;
    color: white;
    transition: .2s;
    opacity: .9;
  }
}

.smallButton {
  @extend .button;
  margin-top: 1rem;
}

.lightButton {
  @extend .button;
  background-color: var(--secondary-color);
  color: var(--primary-color);
  &:hover {
    background-color: var(--third-color);
    color: white;
  }
}

@media only screen and (min-width: 400px) {
  .button {
    font-size: 1.8rem;
    max-width: 40rem;
    margin: 0 auto;
  }
};

@media only screen and (min-width: 768px) {
  .smallButton {
    width: 100%;
    min-width: max-content;
    max-width: 25rem;
  }

};

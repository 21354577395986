.mailButton {
  width: max-content;
  display: flex;
  padding: .8rem 2rem;
  align-items: center;
  font-size: 1.4rem;
  font-family: "Montserrat Medium";
  color: var(--secondary-text);
  background-color: var(--secondary-color);
  border-radius: 2rem;
  width: 100%;
  margin-bottom: 1rem;

  &:nth-child(2) {
    margin-bottom: 2rem;
  }

    &:hover {
      cursor: pointer;
      background-color: var(--primary-color);
      color: var(--secondary-color);
      transition: .2s;

      & .icon:after {
        background-color: var(--secondary-color);
      }
    }

  & .icon {
    display: block;
    width: 2rem;
    height: 2rem;
    margin-right: 1rem;

    &:after {
      display: block;
      content: "";
      width: 2rem;
      height: 2rem;
      background-color: var(--primary-color);
      mask-size: 2rem;
      mask-image: url('/assets/icons/mail.svg');
    }
  }
}

.mailButton {
  margin-right: 1rem;
}

@media only screen and (min-width: 450px) {

  .mailButton {
    width: max-content;
    margin-right: 1rem;
    margin-bottom: 0;

    &:nth-child(2) {
      margin-bottom: 0;
    }
  }

};

@import "./reset.scss";
@import "./colors.module.scss";
@import "./fonts.module.scss";

html {
  font-size: 62.5%;
  box-sizing: border-box;
}

*,
  *:before,
  *:after {
    box-sizing: inherit;
  }

  body {
    padding: 0;
    font-family: "Montserrat Regular", sans-serif;
    height: 100%;
    color: var(--text);
    margin: 0 auto;
  }

  h1, h2 {
    font-family: "Montserrat Medium";
    line-height: 120%;
    margin-bottom: 1.8rem;
  }

  h2 {
    line-height: 100%;
  }

  a {
    text-decoration: none;
    appearance: none;
  }

  p {
    line-height: 150%;
  }


  html {
    min-height: 100%;
  }

  button,
  input {
    appearance: none;
  }

  @media only screen and (min-width: 768px) {
    h1,h2 {
      margin-bottom: 3.5rem;
    }
  };

  @media only screen and (min-width: 1720px) {


    body {
      padding: 0 10vw !important;
    }
    
  };

@import 'src/styling/general.module.scss';

.heroImage {
  width: 100%;
  height: 40vw;
  min-height: 30rem;
  object-fit: cover;
}

.heroContent {
  padding: 3rem 2.5rem 10rem;
  width: 100%;
  margin: 0 auto;
}

.heroTitle {
  @extend .title;
  font-family: "Montserrat SemiBold";
  margin-bottom: 2.5rem;
  color: var(--primary-color);

  & span {
    font-size: 2.1rem;
    font-family: "Montserrat Medium";
    color: var(--secondary-text);
    display: block;
  }
}

.heroText {
  @extend .text;

  &:nth-child(3) {
    margin-bottom: 5rem;
  }
}

.heroLink {
  text-align: center;
  margin-top: 1.5rem;
  font-size: 1.4rem;
  display: block;
  width: 100%;
  color: var(--secondary-text);
}

@media only screen and (min-width: 400px) {
  .heroLink {
    font-size: 1.6rem;
  }
};

@media only screen and (min-width: 768px) {

  .heroContent {
    padding: 7rem 9rem;
  }

  .heroText {
    text-align: justify;
  }

};

@media only screen and (min-width: 1024px) {

  .hero {
    display: grid;
    grid-template-columns: 1fr calc(100% / 2.32);
  }

  .heroContent {
    padding: 15rem 9rem 7rem;
  }

  .heroImage {
    height: 100%;
  }

  
};

@media only screen and (min-width: 1720px) {


  .heroContent {
    padding: 7rem 9rem;
  }
  
};

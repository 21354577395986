// wrappers
.header {
  background-color: var(--secondary-color);
}

.main {
  border-radius: var(--borderRadius);
  width: 100%;
  margin: 0 auto;
}

// el
.title {
  font-size: 2.1rem;
}

.subtitle {
  font-size: 2.1rem;
}

.text {
  font-size: 1.6rem;
  margin-bottom: 1.8rem;

  & span {
    font-family: "Montserrat Medium";
  }

  &:last-child {
    margin-bottom: 0;
  }
}

.crossIcon {
  position: sticky;
  width: 3rem;
  height: 3rem;
  left: 100%;
  top: 2.5rem;
  color: #000;
  z-index: 20000;

  &:hover {
    cursor: pointer;
    color: var(--primary-color);
    transform: scale(1.1);
  }
}

.logoNav {
  width: 100%;
  z-index: 1;
  position: fixed;
  top: 0;
  padding: 0 2.5rem;
  height: 7rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--primary-color);
  color: white;
  box-shadow: 0 .2rem .2rem rgba(0,0,0, .2);
}

.logoWrapper {
  display: flex;
  align-items: center;

  &  .logo {
    width: 7rem;
    height: 7rem;
    margin-right: 1rem;
  }

  & .logoText {
    display: none;
  }
}


.comment {
  @extend .text;
  font-family: "Montserrat Medium";
  color: var(--primary-color);
  margin-bottom: 1rem;
}

.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow-y: hidden;
  margin: auto;
  background-color: rgba(0,0,0, .1);
  z-index: 3;

  &:hover {
    cursor: pointer;
  }

  & .modal {
    position: relative;
    width: 100%;
    height: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    overflow: auto;
    padding: 0 2.5rem 2.5rem;
    background-color: white;
    border-radius: var(--borderRadius);

    &:hover {
      cursor: default;
    }

    & .modalWrapper {
      position: relative;
      display: block;
      margin: 1rem 0;
    }

    & .modalTitle {
      @extend .title;
      position: sticky;
      top: 0;
      background-color: white;
      z-index: 5000;
      padding: 3rem 0 2rem;
      color: var(--text);
      margin-top: -5rem;
      margin-bottom: 4rem;
      border-bottom: .1rem solid var(--primary-color);
    }

    & .modalSubtitle {
      @extend .text;
      text-align: center;
      padding: 1rem 0;
      background-color: var(--fifth-color);
    }

    & .modalText {
      @extend .text;
      color: var(--text);

      p {
        margin-top: 15px;
        text-align: justify;
      }

      ul {
        list-style: initial;
        li {
          margin-left: 25px;
          margin-top: 10px;
        }
      }
      
      i {
        font-style: italic;
      }

      b {
        font-style: bold;
      }
      
    }

    & .modalImage {
      margin-bottom: 2rem;
      width: 100%;
      max-width: 45rem;
      object-fit: contain;
      border-radius: var(--borderRadius);
    }

    & .iframe {
      position: relative;
      width: 100%;
      height: 29vh;
    }
  }
}


@media only screen and (min-width: 400px) {


  .title {
    font-size: 2.4rem;
  }

  .subtitle {
    font-size: 2.1rem;
  }

  .text {
    font-size: 1.8rem;
  }

  .heroLink {
    font-size: 1.6rem;
  }
};


@media only screen and (min-width: 450px) {

  .callWrapper {
    display: flex;
    margin-bottom: 2rem;
  }
}

@media only screen and (min-width: 768px) {

  .title, .subtitle {
    font-size: 3.5rem;
  }


  .text {
    font-size: 2.1rem;
    maegin: 2rem auto;
  }

  .comment {
    margin-bottom: 1rem;
  }


};

@media only screen and (min-width: 1024px) {

  .main {
    display: grid;
    grid-template-columns: 10vw 10fr 1fr max-content 10vw;
    grid-template-areas: 
    ". info . doctors ."
    "services services services services services"
    "practical practical practical practical practical";
  }

  .logoNav {
    display: none;
  }

  .modalOverlay .modal {
    max-width: 85rem;
    height: 80%;

    & .modalImage {
      max-width: 30rem;
    }
  }

};

@media only screen and (min-width: 1700px) {

  .header {
    position: relative;

    &:before {
      content: "";
      background-color: var(--secondary-color);
      position: absolute;
      top: 0;
      left: 50%;
      width: 100vw;
      height: 100%;
      transform: translate(-50%);
      z-index: -1;
    }
  }

  .footer {
    position: relative;

    &:before {
      content: "";
      background-color: var(--fourth-color);
      position: absolute;
      top: 0;
      left: 50%;
      width: 100vw;
      height: 100%;
      transform: translate(-50%);
      z-index: -1;
    }
  }

};

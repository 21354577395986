@import '../../styling/general.module.scss';

.overlay {
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  background-color: var(--overlayBg);

  &:hover {
    cursor: pointer;
  }
}

.nav {
  width: 70vw;
  height: 100vh;
  position: absolute;
  top: 0;
  right: 0;
  background-color: white;
  z-index: 2;

  & .logoInNavWrapper {
    position: relative;
    width: 100%;
    height: 100%;
    margin-bottom: 10rem;

    & .logoInNav {
      width: 70vw;
      height: 6rem;
      margin: 0 auto;
    }

    & p {
      @extend .text;
      font-family: "Montserrat SemiBold";
      text-align: center;
      margin: 1rem auto;
      & span { 
        display: block;
        font-family: "Montserrat SemiBold";
      }
    }
  }


  & .close {
    z-index: 3;
    position: absolute;
    bottom: 3.5rem;
    right: 3.5rem;
    color: var(--text);
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    text-transform: uppercase;
    font-size: 1.6rem;

    & .cross {
      background-color: var(--primary-color);
      width: 2.4rem;
      height: 2.4rem;
      mask-size: 2.4rem;
      mask-image: url('/assets/icons/cross.svg');
      margin-left: 2rem;
    }
  }

  & .logoWrapper {
    display: flex;
    align-items: center;
    &  .logo {
      width: 4rem;
      height: 4rem;
      margin-right: 1rem;
    }

    & .logoText {
      display: block !important;
      font-size: 1.6rem;
    }
  }

  & .list {
    position: fixed;
    bottom: 0;
    width: 100%;
    padding: 0 0 15vh;

    & .menu, .listItem {
      @extend .title;
      margin-bottom: 3rem;
      padding: 0 5rem;
    }

    & .listItem:hover {
      font-family: "Montserrat Medium";
      color: var(--primary-color);
      transition: .2s;
    }

    & .menu {
      @extend .title;
      font-family: "Montserrat SemiBold";
    }

  }
}

.hamburger {
  z-index: 2;
  position: fixed;
  width: 5rem;
  height: 5rem;
  border-radius: 2.5rem;
  bottom: 2rem;
  right: 2rem;
  background-color: var(--primary-color);
  box-shadow: 0 0 .5rem .2rem rgba(0,0,0, .2);
  &:after {
    display: block;
    content:"";
    width: 5rem;
    height: 5rem;
    background-color: white;
    mask-size: 2rem;
    mask-image: url('/assets/icons/hamburger.svg');
    mask-repeat: no-repeat;
    mask-position: center;
  }

  &:hover {
    cursor: pointer;
  }
}

.navDesktop {
  display: none;
}

@media only screen and (min-width: 1024px) {

  .hamburger {
    display: none;
  }

  .navDesktop {
    position: fixed;
    top: 0;
    box-shadow: 0 0.2rem 0.2rem rgb(0 0 0 / 20%);
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    width: 100%;
    height: 10rem;
    padding: 3rem 5vw;
    background-color: var(--primary-color);

    & .logoWrapper {
      position: absolute;
      left: 5vw;
    }

    & .list {
      position: relative;
      display: flex;
      align-items: center;
      height: 100%;
      padding: 0;
      margin-right: 3rem;

      & .listItem {
        color: var(--secondary-color);
        font-size: 1.6rem;
        margin-bottom: 0;
        margin-right: 3rem;
        padding: 0;

        &:hover {
          font-family: "Montserrat Medium";
          cursor: pointer;
          transition: .2s;
        }
      }
    }
  }
};

@media only screen and (min-width: 1100px) {

  .navDesktop .logoWrapper .logoText {
    display: block;
    color: white;
    font-size: 1.6rem;
  }
};


@media only screen and (min-width: 1700px) {

  .navDesktop {
    position: relative;
    padding: 0;

    &:before {
      content: "";
      background-color: var(--primary-color);
      position: absolute;
      top: 0;
      left: 50%;
      width: 100vw;
      height: 100%;
      transform: translate(-50%);
      z-index: -1;
    }

    & .logoWrapper {
      left: 0;
    }
  }
}

@font-face {
  font-family: "Montserrat Light";
  font-style: normal;
  font-weight: 200;
  src: url("/assets/fonts/Montserrat-Light.woff2");
  src: url("/assets/fonts/Montserrat-Light.woff");
}

@font-face {
  font-family: "Montserrat Regular";
  font-style: normal;
  font-weight: 300;
  src: url("/assets/fonts/Montserrat-Regular.woff2");
  src: url("/assets/fonts/Montserrat-Regular.woff");
}

@font-face {
  font-family: "Montserrat Medium";
  font-style: normal;
  font-weight: 400;
  src: url("/assets/fonts/Montserrat-Medium.woff2");
  src: url("/assets/fonts/Montserrat-Medium.woff");
}

@font-face {
  font-family: "Montserrat SemiBold";
  font-style: normal;
  font-weight: 400;
  src: url("/assets/fonts/Montserrat-SemiBold.woff2");
  src: url("/assets/fonts/Montserrat-SemiBold.woff");
}

@import '../general.module.scss';

.footerWrapper {
  width: 100%;
  height: 100%;
  padding: 5rem;
  background-color: var(--fourth-color);
}

.logo {
  width: 100%;
  margin-bottom: 2rem;
}

.copy {
  @extend .text;
  color: var(--secondary-color);
  width: 100%;
  line-height: 120%;
  text-align: center;
}


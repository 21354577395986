@import '../../styling/general.module.scss';

.title {
  color: white;
  font-size: 2.5rem;
  margin-left: 10%;
  margin-right: 10%;
  text-align: justify !important;
}

.servicesWrapper {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 5rem 2.5rem;
  background-color: var(--primary-color);

  & .newsSlider {
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    width: calc(100vw - 6rem);
    margin: 0 auto 2rem;
    overflow-x: hidden;
    user-select: none;

    & .slide {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 100%;
      border-radius: var(--borderRadius);
      background-color: white;

      & .slideTitle {
        font-size: 2.1rem;
        padding:  2.5rem 2rem 1rem;
        margin-bottom: 0;
      }

      & .slideText {
        font-size: 1.6rem;
        padding:  0 2rem 2.5rem;
      }

      &  .slideImage {
        width: 100%;
        height: 20rem;
        object-fit: cover;
        border-radius: .7rem .7rem 0 0;
      }
    }
  }

  .iconWrapper {
    width: 100%;
    height: fit-content;
    justify-content: space-between;
    display: flex;
    margin-bottom: 5rem;
  }

  .arrowIcon {
    position: relative;
    display: grid;
    place-items: center;
    width: 5rem;
    height: 5rem;
    border-radius: 2.5rem;
    background-color: var(--secondary-color);

    &:hover {
      cursor: pointer;
    }

    & .icon {
      width: 2.4rem;
      height: 2.4rem;
      color: var(--primary-color);
    }
  }
};

@media only screen and (min-width: 1024px) {

  .title {
    margin-bottom: 5rem;
  }

  .servicesWrapper {
    grid-area: services;
    position: relative;
    padding: 10rem 0;

    & .newsSlider {
      width: calc(100% - 20vw);
      border-radius: var(--borderRadius);
      padding: 0;
      margin: 0 0 5rem;

      & .slide {
        width: 35rem;
        &:hover {
          cursor: pointer;
        }
      }

    }

    & .iconWrapper {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 92%;
    }
  }

  .title {
    text-align: center;
  }

};

@media only screen and (min-width: 1440px) {

  .servicesWrapper {
    & .newsSlider .slide {
      width: 100%;
    }
  }
};

@media only screen and (min-width: 1700px) {

  .servicesWrapper {
    position: relative;
    &:before {
      content: "";
      display: block;
      background-color: var(--primary-color);
      position: absolute;
      top: 0;
      left: 50%;
      width: 100vw;
      height: 100%;
      transform: translate(-50%);
      z-index: -1;
    }
  }
};

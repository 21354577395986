@import '../general.module.scss';

.doctors {
  width: 90%;
  margin: 0 auto;
  padding: var(--padding);
}

.doctor {
  display: grid;
  place-items: center;
  padding: 3rem 0;
  text-align:center;
}

.image {
  width: 50vw;
  height: 50vw;
  max-width: 20rem;
  max-height: 20rem;
  background-color: var(--secondary-color);
  border-radius: 1rem;
}

.name {
  font-size: 1.8rem;
  margin: 2rem 0 2rem;
}

@media only screen and (min-width: 768px) {

  .name {
    font-size: 2.1rem;
    margin-top: 0;
    text-align: start;
  }

  .doctors {
    width: 100%;
    max-width: 124rem;
    padding:  5rem 10rem 10rem;
  }

  .doctor {
    display: flex;
    align-items: flex-start;
    padding: 0;

    &:not(first-of-type) {
      margin-bottom: 4rem;
    }

  }

  .image {
    margin-right: 3rem;
  }

};

@media only screen and (min-width: 1024px) {

  .image {
    max-width: 15rem;
    max-height: 15rem;
  }

  // .image:hover {
  //   box-shadow: 0 0 .1rem .1rem .2rem rgba(0,0,0, .3);
  //   transition: .2s;
  // }

  .doctors {
    grid-area: doctors;
    padding: 10rem 0;
  }

  
};



@import '../../styling/general.module.scss';


.practicalWrapper {
  padding: 5rem var(--padding);
  grid-area: practical;
  width: 100%;
  background-color: var(--third-color);
}

.location, .openingshours {
  // padding: 5rem 0;
}

.title {
  color: white;
}

.address {
  @extend .text;
  font-family: "Montserrat Light";
  margin-bottom: .5rem;
  color: white;
}

.underlineText {
  @extend .address;
  text-decoration: underline;
  margin: 0 0 3rem;
}

.map {
  width: 100%;
  height: 30rem;
  background-color: var(--fourth-color);
  border-radius: var(--borderRadius);

  & .googleMap {
    width: 100%;
    height: 100%;
  border-radius: var(--borderRadius);
  }
}

.openingshours {
  margin: 5rem 0;
}

.dayWrapper {
  position: relative;
  display: flex;


  & .day, .hours {
    @extend .text;
    margin-bottom: .5rem;
    color: white;
  }

  & .hours {
    position: absolute;
    left: 15rem;
    font-family: "Montserrat Light";

    & a {
      color: white;
      font-family: "Montserrat Medium";
      text-decoration: underline;
    }
  }

}


@media only screen and (min-width: 1024px) {

  .practicalWrapper {
    position: relative;
    padding: 10rem 0;
    display: grid;
    grid-template-columns: 10vw 1fr 10vw 1fr 10vw;
    grid-template-areas: ". location . openingshours .";
  }


  .location {
    grid-area: location;
  }

  .openingshours {
    margin-top: 0;
    grid-area: openingshours;
  }

};


@media only screen and (min-width: 1700px) {

  .practicalWrapper:before {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: 50%;
      width: 100vw;
      height: 100%;
      transform: translate(-50%);
      z-index: -1;
      background-color: var(--third-color);
    }
  
}

@import '../../styling/general.module.scss';

.appointments {
  width: 90%;
  margin: -6rem auto 0;
  padding: var(--padding);
  background-color: white;
  border-radius: var(--borderRadius);
  text-align: justify;
}

.callWrapper {
  justify-content: center;
}

.imageWrapper {
  display: grid;
  grid-template-areas: "mask""covid""doctor";
  grid-template-columns: 1fr;
  grid-gap: 2rem;

  & .image {
    width: 100%;
    height: 20vh;
    border-radius: var(--borderRadius);
    object-fit: cover;

    &:nth-child(1) {
      grid-area: mask;
    }

    &:nth-child(2) {
      grid-area: covid;
    }

    &:nth-child(3) {
      grid-area: doctor;
    }
  }

}

@media only screen and (min-width: 768px) {

  .appointments {
    width: 100%;
    padding: 10rem 10rem 5rem;
    margin: 0;
  }

  .imageWrapper {
    grid-template-columns: 3fr 2fr;
    grid-template-rows: 1fr 1fr;
    grid-template-areas: "mask doctor""covid doctor";

    & .image {
      height: 100%;
    }

  }
};

@media only screen and (min-width: 1024px) {

  .appointments {
    padding: 10rem 4rem 10rem 0;
    grid-area: info;
  }
};
